import { useRef, useState, useEffect, useReducer, useCallback, useMemo, useLayoutEffect, useDebugValue } from 'react';
import { isChanged, createProxy, affectedToPathList } from 'proxy-compare';
import { snapshot, subscribe, getVersion } from 'valtio/vanilla';
export { getVersion, proxy, ref, snapshot, subscribe, getHandler as unstable_getHandler } from 'valtio/vanilla';

const TARGET = "_uMS_T";
const GET_VERSION = "_uMS_V";
const createMutableSource = (target, getVersion) => ({
  [TARGET]: target,
  [GET_VERSION]: getVersion
});
const useMutableSource = (source, getSnapshot, subscribe) => {
  const lastVersion = useRef();
  const currentVersion = source[GET_VERSION](source[TARGET]);
  const [state, setState] = useState(() => [
    source,
    getSnapshot,
    subscribe,
    currentVersion,
    getSnapshot(source[TARGET])
  ]);
  let currentSnapshot = state[4];
  if (state[0] !== source || state[1] !== getSnapshot || state[2] !== subscribe) {
    currentSnapshot = getSnapshot(source[TARGET]);
    setState([
      source,
      getSnapshot,
      subscribe,
      currentVersion,
      currentSnapshot
    ]);
  } else if (currentVersion !== state[3] && currentVersion !== lastVersion.current) {
    currentSnapshot = getSnapshot(source[TARGET]);
    if (!Object.is(currentSnapshot, state[4])) {
      setState([
        source,
        getSnapshot,
        subscribe,
        currentVersion,
        currentSnapshot
      ]);
    }
  }
  useEffect(() => {
    let didUnsubscribe = false;
    const checkForUpdates = () => {
      if (didUnsubscribe) {
        return;
      }
      try {
        const nextSnapshot = getSnapshot(source[TARGET]);
        const nextVersion = source[GET_VERSION](source[TARGET]);
        lastVersion.current = nextVersion;
        setState((prev) => {
          if (prev[0] !== source || prev[1] !== getSnapshot || prev[2] !== subscribe) {
            return prev;
          }
          if (Object.is(prev[4], nextSnapshot)) {
            return prev;
          }
          return [
            prev[0],
            prev[1],
            prev[2],
            nextVersion,
            nextSnapshot
          ];
        });
      } catch (e) {
        setState((prev) => [...prev]);
      }
    };
    const unsubscribe = subscribe(source[TARGET], checkForUpdates);
    checkForUpdates();
    return () => {
      didUnsubscribe = true;
      unsubscribe();
    };
  }, [source, getSnapshot, subscribe]);
  return currentSnapshot;
};

const isSSR = typeof window === "undefined" || !window.navigator || /ServerSideRendering|^Deno\//.test(window.navigator.userAgent);
const useIsomorphicLayoutEffect = isSSR ? useEffect : useLayoutEffect;
const useAffectedDebugValue = (state, affected) => {
  const pathList = useRef();
  useEffect(() => {
    pathList.current = affectedToPathList(state, affected);
  });
  useDebugValue(pathList.current);
};
const mutableSourceCache = /* @__PURE__ */ new WeakMap();
const getMutableSource = (proxyObject) => {
  if (!mutableSourceCache.has(proxyObject)) {
    mutableSourceCache.set(proxyObject, createMutableSource(proxyObject, getVersion));
  }
  return mutableSourceCache.get(proxyObject);
};
const useSnapshot = (proxyObject, options) => {
  const forceUpdate = useReducer((c) => c + 1, 0)[1];
  const affected = /* @__PURE__ */ new WeakMap();
  const lastAffected = useRef();
  const prevSnapshot = useRef();
  const lastSnapshot = useRef();
  useIsomorphicLayoutEffect(() => {
    lastSnapshot.current = prevSnapshot.current = snapshot(proxyObject);
  }, [proxyObject]);
  useIsomorphicLayoutEffect(() => {
    lastAffected.current = affected;
    if (prevSnapshot.current !== lastSnapshot.current && isChanged(prevSnapshot.current, lastSnapshot.current, affected, /* @__PURE__ */ new WeakMap())) {
      prevSnapshot.current = lastSnapshot.current;
      forceUpdate();
    }
  });
  const notifyInSync = options == null ? void 0 : options.sync;
  const sub = useCallback((proxyObject2, cb) => subscribe(proxyObject2, () => {
    const nextSnapshot = snapshot(proxyObject2);
    lastSnapshot.current = nextSnapshot;
    try {
      if (lastAffected.current && !isChanged(prevSnapshot.current, nextSnapshot, lastAffected.current, /* @__PURE__ */ new WeakMap())) {
        return;
      }
    } catch (e) {
    }
    prevSnapshot.current = nextSnapshot;
    cb();
  }, notifyInSync), [notifyInSync]);
  const currSnapshot = useMutableSource(getMutableSource(proxyObject), snapshot, sub);
  if (typeof process === "object" && process.env.NODE_ENV !== "production") {
    useAffectedDebugValue(currSnapshot, affected);
  }
  const proxyCache = useMemo(() => /* @__PURE__ */ new WeakMap(), []);
  return createProxy(currSnapshot, affected, proxyCache);
};

export { useSnapshot };
